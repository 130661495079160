<swe-pageheader [header]="languageService.getItem(876)"></swe-pageheader>
<div class="row d-block py-2">
  <div class="col-12 col-md-4 float-start">
    <swe-card [header]="languageService.getItem(479)" [open]="true" [hasCollapse]="false" [hasRefresh]="false">
      <swe-element [type]="'System.Boolean'" [label]="'DebugMode'" [statusLabel]="1" [(model)]="debugmode" (modelChange)="changeDebug()"></swe-element>
      <swe-element [type]="'System.Boolean'" [label]="'Regelnålar (Ctrl, Alt R)'" [statusLabel]="1" [model]="adminmode" (modelChange)="changeAdminRule()"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="clearcache()"><i class="bi bi-trash-fill"></i>&nbsp;{{languageService.getItem(115)}}</button>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4 float-start" [ngClass]="{'col-md-4': table.Cols==1,'col-md-8': table.Cols==2, 'col-md-12': table.Cols==3}" *ngFor="let table of tables">
    <swe-card [header]="table.DisplayName" [open]="table.open" [hasCollapse]="true" [hasRefresh]="true" (openChange)="getTable(table)" (refreshChange)="getTable(table,true)">
      <div *ngIf="table.Description.length>0">
        <pre>{{table.Description}}</pre>
      </div>
      <div class="table-responsive">
        <table class="table table-sm table-striped table-bordered">
          <thead>
            <tr>
              <th></th>
              <th *ngFor="let header of table.headers">{{header.Name}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of table.rows">
              <tr class="bg-info" *ngIf="row.HasColumnDesc">
                <td><i class="bi bi-info-circle-fill"></i></td>
                <td [attr.colspan]="row.Properties.length+1">{{row.ColumnDesc}}</td>
              </tr>
              <tr [ngClass]="{'bg-danger': row.IsDeleted,'swe-disabled-block': !row.IsActive}">
                <td><span *ngIf="row.Id>0">{{row.Id}}</span></td>
                <td style="white-space:nowrap;" [ngClass]="{'bg-info': i==row.ColumnIndex}" *ngFor="let property of row.Properties; index as i" [ngSwitch]="property.Type">
                  <swe-element [type]="'System.List'" [label]="property.Name + '_' + i" [(model)]="property.Value" (modelChange)="manageColumn(i, table, row)" [items]="getItems(table, property.Name)" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" *ngSwitchCase="'System.List'"></swe-element>
                  <swe-element [type]="'System.GroupByList'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getGroupByItems(table, property.Name)" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" *ngSwitchCase="'System.GroupByList'"></swe-element>
                  <swe-element [type]="'System.MultiList'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getItems(table, property.Name)" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" *ngSwitchCase="'System.MultiList'"></swe-element>
                  <swe-element [type]="'System.LevelSearch'" [label]="property.Name + '_' + i" [(model)]="property.Value" [display]="property.Extra" [optional]="true" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" *ngSwitchCase="'System.Level'"></swe-element>
                  <swe-element [type]="'System.UserSearch'" [label]="property.Name + '_' + i" [(model)]="property.Value" [display]="property.Extra" [optional]="true" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" *ngSwitchCase="'System.User'"></swe-element>
                  <swe-element [type]="'System.TextArea'" [label]="property.Name + '_' + i" [(model)]="property.Value" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" [allownull]="true" [container]="{resize: 'auto'}" *ngSwitchCase="'System.Text'"></swe-element>
                  <span *ngSwitchCase="'System.Label'">{{property.Value}}</span>
                  <swe-element [type]="property.Type" [label]="property.Name + '_' + i" [(model)]="property.Value" [disabled]="table.ReadOnlys.indexOf(property.Name)>-1" [allownull]="true" *ngSwitchDefault></swe-element>
                </td>
                <td><i class="bi bi-swe-fw bi-swe swe-click" [ngClass]="{'bi-trash-fill': !row.IsDeleted, 'bi-arrow-counterclockwise': row.IsDeleted}" *ngIf="row.Id>0" (click)="row.IsDeleted=!row.IsDeleted"></i></td>
              </tr>
            </ng-container>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td *ngFor="let property of table.footer.Properties" [ngSwitch]="property.Type">
                <swe-element [type]="'System.List'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getItems(table, property.Name)" *ngSwitchCase="'System.List'"></swe-element>
                <swe-element [type]="'System.GroupByList'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getGroupByItems(table, property.Name)" *ngSwitchCase="'System.GroupByList'"></swe-element>
                <swe-element [type]="'System.MultiList'" [label]="property.Name + '_' + i" [(model)]="property.Value" [items]="getItems(table, property.Name)" *ngSwitchCase="'System.MultiList'"></swe-element>
                <swe-element [type]="'System.LevelSearch'" [label]="property.Name + '_' + i" [(model)]="property.Value" [optional]="true" *ngSwitchCase="'System.Level'"></swe-element>
                <swe-element [type]="'System.UserSearch'" [label]="property.Name + '_' + i" [(model)]="property.Value" [optional]="true" *ngSwitchCase="'System.User'"></swe-element>
                <swe-element [type]="'System.TextArea'" [label]="property.Name + '_' + i" [(model)]="property.Value" *ngSwitchCase="'System.Text'"></swe-element>
                <span *ngSwitchCase="'System.Label'">{{property.Value}}</span>
                <swe-element [type]="property.Type" [label]="property.Name + '_' + i" [(model)]="property.Value" [allownull]="true" *ngSwitchDefault></swe-element>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="save(table)"><i class="bi bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4 float-start">
    <!--Client-->
    <swe-card [header]="'Client'" [open]="false" [hasCollapse]="true" [hasRefresh]="false">
      <swe-element [type]="'System.String'" [label]="'ClientId'" [statusLabel]="2" [(model)]="clientId"></swe-element>
      <swe-element [type]="'System.String'" [label]="'ValidationKey'" [statusLabel]="2" [(model)]="validationKey"></swe-element>
      <swe-element [type]="'System.String'" [label]="'Lösenord (om annat än ClientId)'" [statusLabel]="2" [(model)]="clientIdPassword"></swe-element>
      <swe-element [type]="'System.String'" [label]="'Secret'" [statusLabel]="2" [model]="secret" [disabled]="true"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="createClient()"><i class="bi bi-pc"></i>&nbsp;Skapa Client</button>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4 float-start">
    <!--Password-->
    <swe-card [header]="'AuthToken'" [open]="false" [hasCollapse]="true" [hasRefresh]="false">
      <swe-element [type]="'System.Int32'" [label]="'AnvändarId'" [statusLabel]="2" [(model)]="userId"></swe-element>
      <swe-element [type]="'System.Int32'" [label]="'Giltigstid i minuter'" [statusLabel]="2" [(model)]="validminutes"></swe-element>
      <swe-element [type]="'System.String'" [label]="'AuthToken'" [statusLabel]="2" [model]="authtoken" [disabled]="true"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="createToken()"><i class="bi bi-pc"></i>&nbsp;Skapa AuthToken</button>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4 float-start">
    <!--Id-->
    <swe-card [header]="'Parse'" [open]="false" [hasCollapse]="true" [hasRefresh]="false">
      <swe-element [type]="'System.String'" [label]="'Id'" [statusLabel]="2" [(model)]="generalId"></swe-element>
      <swe-element [type]="'System.String'" [label]="'Decoded Id'" [statusLabel]="2" [model]="decodedId" [disabled]="true"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="parseId()"><i class="bi bi-pc"></i>&nbsp;Slå upp Id</button>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4 float-start">
    <!--Id-->
    <swe-card [header]="'PushNotifieringar'" [open]="false" [hasCollapse]="true" [hasRefresh]="false">
      <swe-element [type]="'System.String'" [label]="'Text'" [statusLabel]="2" [(model)]="notifyText"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="push()"><i class="bi bi-bell-fill"></i>&nbsp;Skicka till Host prenumerationer</button>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4 float-start">
    <!--Belongings-->
    <swe-card [header]="'Dina tillhörigheter'" [open]="false" [hasCollapse]="true" [hasRefresh]="false" (openChange)="loadBelonging()">
      <swe-element [type]="'System.List'" [label]="'Roller'" [statusLabel]="2" [(model)]="roleId" [items]="roles"></swe-element>
      <swe-element [type]="'System.List'" [label]="'Enheter'" [statusLabel]="2" [(model)]="levelId" [items]="levels"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="saveBelonging()"><i class="bi bi-floppy2-fill"></i>&nbsp;Spara</button>
      </div>
      <div class="list-group mt-3" *ngIf="!advancedbelonging&&userroles.length>0">
        <h3>{{languageService.getItem(82)}}</h3>
        <div class="list-group-item list-group-item-action" *ngFor="let role of userroles">{{role.Name}}</div>
      </div>
      <div class="list-group mt-2" *ngIf="!advancedbelonging&&userlevels.length>0">
        <h3>{{languageService.getItem(3)}}</h3>
        <div class="list-group-item list-group-item-action" *ngFor="let level of userlevels"><span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}</div>
      </div>
      <div *ngIf="advancedbelonging">
        <div class="list-group mt-3" *ngFor="let connection of userconnections">
          <h3>
            <span *ngIf="connection.Role">{{connection.Role.Name}}&nbsp;</span>
          </h3>
          <div class="list-group-item list-group-item-action" *ngFor="let level of connection.Levels"><span class="swe-grayed-out">{{level.Path}}</span>{{level.Name}}</div>
        </div>
      </div>
    </swe-card>
  </div>
  <div class="col-12 col-md-4 float-start">
    <!--Deploy-->
    <swe-card [header]="'Deploy'" [open]="false" [hasCollapse]="true" [hasRefresh]="false">
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="deploy()"><i class="bi bi-gear-wide-connected"></i>&nbsp;Kör deploy jobb</button>
      </div>
    </swe-card>
  </div>
</div>
<!--To make float behavior (fill available space), use [d-block] on row and [float-start] on columns-->
