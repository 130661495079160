import { Component, OnDestroy, OnInit } from '@angular/core';

import { DataService } from '../_services/data.service';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';
import { LanguageService } from '../_services/language.service';
import { Subject, Subscription } from 'rxjs';
import { GeneralService } from '../_services/general.service';
import { PermissionService } from '../_services/permission.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html'
})
export class GuideComponent implements OnInit, OnDestroy {

  private _openMenu: boolean = false;
  private _keywords: string[] = [];
  private _guides: any[] = [];
  private _selected: any;
  private _search: string = '';
  private _more: boolean = false;
  private _isLoaded: boolean = false;
  private searchSubject: Subject<string> = new Subject<string>();
  private routerSubscription: Subscription;

  private _multiple: number = 1;
  private _top: number = 25;

  private _prevFilter: any = {};

  public get openMenu(): boolean {
    return this._openMenu;
  }
  public set openMenu(value: boolean) {
    this._openMenu = value;
  }

  public get guides() {
    return this._guides;
  }
  public get more() {
    return this._more;
  }
  public get selected() {
    return this._selected;
  }
  public get search() {
    return this._search;
  }
  public set search(value) {
    this._search = value;
  }
  public get isLoaded() {
    return this._isLoaded;
  }

  constructor(public generalService: GeneralService, public languageService: LanguageService, public dataService: DataService, public permissionService: PermissionService, private router: Router) {
  }
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
  ngOnInit() {
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this._openMenu = false;
      this._selected = null;
      this._search = '';
      if (event.url == '/') {
        return;
      }
      const oldPage = this._keywords;
      this._keywords = event.url.split('/');
      if (this._keywords != oldPage) {
        this.openMenu = false;
        this.loadGuides();
      }
    });

    this.searchSubject.pipe(
      debounceTime(1000) 
    ).subscribe(searchText => {
      this._search = searchText;
      this.loadGuides();
    });
  }
  public manageSearch() {
    this.searchSubject.next(this._search);
  }
  public loadGuides(more: boolean = false) {
    if (!this._openMenu) {
      return;
    }

    this._isLoaded = false;
    const filter = {
      Keywords: this._search ? -1 : this.getKeywords(),
      Search: this._search,
      Multiple: 0,
      Top: 0
    };

    if (!more && this._prevFilter.Search === filter.Search && this._prevFilter.Keywords === filter.Keywords) {
      return;
    }

    this._prevFilter = filter;
    if (more) {
      this._multiple = 1 + (this._multiple / 4);
      this._top *= 4;
    } else {
      this._multiple = 1;
      this._guides = [];
    }

    filter.Multiple = this._multiple;
    filter.Top = this._top;

    this.dataService.tokenRequest('/api/v1/guides/published/search', 'POST', filter).subscribe(res => {
      if (res) {
        this._guides.push(...res.Guides);
        this._more = res.More;
      }
      this._isLoaded = true;
    });
  }

  close() {
    this._selected = null;
  }
  public openGuide(guide) {
    this._openMenu = false;
    this._selected = guide;
  }

  private getKeywords() {
    const entries = Object.entries(GuideKeywords).filter(([key, value]) => isNaN(Number(key)));
    let keywords = 0;
    this._keywords.forEach(x => {
      const foundEntry = entries.find(([key]) => key.toLowerCase() === x.toLowerCase());
      if (foundEntry) {
        keywords += Number(foundEntry[1]);
      }
    })
    return keywords;
  }


}

export enum GuideKeywords
{
  Admin = 1,
  Home = 2,
  Users = 4,
  UserMarkedDates = 8,
  Bookings = 16,
  Timereports = 32,
  Timeline = 64,
  Levels = 128,
  Objects = 256,
  Reports = 512,
  Messages = 1024,
  Logs = 2048,
  News = 4096,
  Import = 8192,
  ReportArchive = 16384,
  Holiday = 32768,
  Overtime = 65536,
  Contracts = 131072,
  Employments = 262144,
  Articles = 524288,
  Integrations = 1048576,
  Templates = 2097152,
  Economy = 4194304,
  Guides = 8388608,
  Properties = 16777216,
}

